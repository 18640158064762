h1{
  text-align: center;
}

.about{
  height: 80vh;
  width:80vw;
  margin:0 auto;
}

p{
  /* padding:10px; */
}

.sectionHead{
  margin-bottom: 10vh;
}

@media (min-width:800px){
  .about{
    width:80vw;
    margin-left:auto;
    margin-right:auto;
  }
  
  .about p{
    line-height: 35px;
  }

}