.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenavClose{
  height: 100%;
  width: 150px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenavClose button{
  background:none;
  border:none;
}

a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: rgb(162, 77, 131);
  display: block;
  transition: 0.3s;
}

a:hover {
  color: #f1f1f1;
}

span{
  color:white;
}

.closebtn{
  text-align: right;
}

header{
  margin: 15px auto;
}

i{
  color: rgb(162, 77, 131);
}

.topInline{
  display: inline-flex;
  justify-content: space-around;
  width:95%;
}

.topham{
  background: none;
  border:none;
  font-size: 25px;
}

@media (min-width:800px){
a{
  font-size: 35px;
}
i{
  font-size:35px;
}
.sidenavClose{
  width:20vw;
}
}
