.projectList{
  margin-top:26vh;
  height:150vh;
  width: 80vw;
  margin-left:auto;
  margin-right:auto;
}

.project h1 {
  font-size: 28px;
  color: rgb(162, 77, 131);;
  margin-bottom: 3vh;
  margin-top:8vh;
}

.project h4 {
  width: 80vw;
  margin: 0 auto;
  text-align: justify;
  color: white;
}

.projectList h3 {
  font-size:18px;
  color: white;
  text-align: center;
  margin-bottom: 10vh;
  font-style: italic;
}

.project a {
  font-size: 20px;
  text-align: left;
}

.project a:link{
  color:white;
  text-decoration: underline;
}

.project a:visited{
  color: rgb(144, 149, 168);
}

.projectIMG{
  margin-top: 25px;
  height:25vh;
  display: block;
  margin-left:auto;
  margin-right:auto;
  width: 80vw;
}

.project button{
  background: none;
  border: none;
}

.buttons{
  margin-top:20px;
  margin-left:auto;
  margin-right:auto;
  width:fit-content;
}

@media (min-width:800px){
  
  .project h1{
    margin-top:10vh;
    font-size: 30px;
  }

  .project p{
    line-height: 35px;
  }

  .project h4{
    font-size: 26px;
  }

  .project img{
    height:70vh;
    width:80vw;
  }


  .project i {
    font-size: 26px;
  }

  .project i:hover{
    color: white;
  }

}