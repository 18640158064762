h1{
  margin-bottom: -5px;
  color: rgb(204, 214, 246);
  font-size: 25px;
}

.bottomHead{
  color: rgb(144, 149, 168);
  margin-bottom: 50px;
}
.home p{
  text-align: left;
  margin-left:10%;
}


p{
  color:	rgb(162, 77, 131);
  font-size: 17px;
  word-spacing: 3px;
  line-height: 20px;
  text-align: justify;
}

.home{
  margin-top:25vh;
  padding-left:3vw;
  padding-right:3vw;
  height:70vh;
}

@media (min-width:800px){
  h1{
    font-size: 45px;
  }

  p{
    font-size: 26px;
  }

}