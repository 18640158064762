@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenavClose{
  height: 100%;
  width: 150px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenavClose button{
  background:none;
  border:none;
}

a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: rgb(162, 77, 131);
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

a:hover {
  color: #f1f1f1;
}

span{
  color:white;
}

.closebtn{
  text-align: right;
}

header{
  margin: 15px auto;
}

i{
  color: rgb(162, 77, 131);
}

.topInline{
  display: inline-flex;
  justify-content: space-around;
  width:95%;
}

.topham{
  background: none;
  border:none;
  font-size: 25px;
}

@media (min-width:800px){
a{
  font-size: 35px;
}
i{
  font-size:35px;
}
.sidenavClose{
  width:20vw;
}
}

h1{
  margin-bottom: -5px;
  color: rgb(204, 214, 246);
  font-size: 25px;
}

.bottomHead{
  color: rgb(144, 149, 168);
  margin-bottom: 50px;
}
.home p{
  text-align: left;
  margin-left:10%;
}


p{
  color:	rgb(162, 77, 131);
  font-size: 17px;
  word-spacing: 3px;
  line-height: 20px;
  text-align: justify;
}

.home{
  margin-top:25vh;
  padding-left:3vw;
  padding-right:3vw;
  height:70vh;
}

@media (min-width:800px){
  h1{
    font-size: 45px;
  }

  p{
    font-size: 26px;
  }

}
h1{
  text-align: center;
}

.about{
  height: 80vh;
  width:80vw;
  margin:0 auto;
}

p{
  /* padding:10px; */
}

.sectionHead{
  margin-bottom: 10vh;
}

@media (min-width:800px){
  .about{
    width:80vw;
    margin-left:auto;
    margin-right:auto;
  }
  
  .about p{
    line-height: 35px;
  }

}
html{
  background-color: rgb(22, 22, 33);
  font-family: 'Raleway', sans-serif;
}
.projectList{
  margin-top:26vh;
  height:150vh;
  width: 80vw;
  margin-left:auto;
  margin-right:auto;
}

.project h1 {
  font-size: 28px;
  color: rgb(162, 77, 131);;
  margin-bottom: 3vh;
  margin-top:8vh;
}

.project h4 {
  width: 80vw;
  margin: 0 auto;
  text-align: justify;
  color: white;
}

.projectList h3 {
  font-size:18px;
  color: white;
  text-align: center;
  margin-bottom: 10vh;
  font-style: italic;
}

.project a {
  font-size: 20px;
  text-align: left;
}

.project a:link{
  color:white;
  text-decoration: underline;
}

.project a:visited{
  color: rgb(144, 149, 168);
}

.projectIMG{
  margin-top: 25px;
  height:25vh;
  display: block;
  margin-left:auto;
  margin-right:auto;
  width: 80vw;
}

.project button{
  background: none;
  border: none;
}

.buttons{
  margin-top:20px;
  margin-left:auto;
  margin-right:auto;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
}

@media (min-width:800px){
  
  .project h1{
    margin-top:10vh;
    font-size: 30px;
  }

  .project p{
    line-height: 35px;
  }

  .project h4{
    font-size: 26px;
  }

  .project img{
    height:70vh;
    width:80vw;
  }


  .project i {
    font-size: 26px;
  }

  .project i:hover{
    color: white;
  }

}
